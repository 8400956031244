/* BetterCountdown.css */

.countdown-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    background-color: #f2f2f2;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
  }
  
  .countdown-item {
    margin: 0 15px;
    text-align: center;
  }
  
  .countdown-label {
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
  }
  
  .countdown-value {
    font-size: 24px;
    font-weight: bold;
    color: #3498db;
  }
  
  
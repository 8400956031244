.header {
  text-align: center;
  font-family: 'Crimson Pro', serif;
  color: red;
}

.timeline {
  align-items: center;
  justify-content: center;
  width: 100%;
  display: inline-block;
  background-color: pink;
}

.vertical-timeline-element-title {
  font-family: 'Great Vibes';
  padding-top: 20px;
  margin: 0;
  font-size: 36px;
  font-weight: bold;
}

.vertical-timeline-element-subtitle,
.vertical-timeline-element-content {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.timeline-image {
  width: 300px;
  height: 300px;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
}

.timeline-image img {
  width: 100%;
  height: auto;
  display: block;
}

.timeline-element {
  width: 50%;
  background-color: pink;
  border-radius: 8px;
  padding: 20px;
}

.icon {
  color: #ff0000;
}
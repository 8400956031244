/* src/components/PasswordModal.css */
.password-modal {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: pink;
    border-radius: 8px;
  }
  
  .password-modal h2 {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .password-modal form {
    display: flex;
    flex-direction: column;
  }
  
  .password-modal label {
    margin-bottom: 10px;
    text-align: center;
  }
  
  .password-modal input {
    padding: 8px;
    margin-bottom: 15px;
    margin-left: 10px;
    text-align: center;
  }
  
  .password-modal button {
    padding: 10px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .password-modal button:hover {
    background-color: #45a049;
  }
  